<template>
  <b-card header="Assign Member">
    <b-form @submit.prevent="assignMember">
      <auto-suggest
        id="users-search"
        label="Users Search"
        text-field="username"
        :min-search-chars="6"
        :suggestions="users"
        :value-reducer="option=>option.item.username"
        :data-provider="getUsers"
        :selected.sync="username"
      />

      <b-button
        variant="primary"
        type="submit"
      >
        Assign
      </b-button>

      <back />
    </b-form>
  </b-card>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/FormInputs/Back.vue'
import AutoSuggest from '@/common/components/common/FormInputs/AutoSuggest.vue'

export default {
  name: 'AssignMember',
  components: {
    Back,
    AutoSuggest,
  },
  data() {
    return {
      users: [],
      username: '',
    }
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()

    return {
      successfulOperationAlert,
    }
  },
  methods: {
    getUsers(search = null) {
      this.$portalUsers.get('v1/users/search', {
        params: {
          search,
        },
      }).then(res => {
        this.users = res.data.data
      })
    },
    assignMember() {
      this.$portalUsers.post(`v1/user-assign-roles/${this.username}`, {
        roles: [
          'team',
        ],
        entityId: null,
      }).then(() => {
        this.successfulOperationAlert('User is assigned successfully')
        this.$router.go(-1)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
