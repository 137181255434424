<template>
  <b-form-group
    :label="label"
    :label-for="id"
  >
    <vue-autosuggest
      :id="id"
      v-model="selectedProxy"
      :suggestions="[{data:suggestions}]"
      :get-suggestion-value="valueReducer"
      :input-props="{id:'autosuggest__input',class:'form-control', placeholder:placeholder}"
      @input="onInputChange"
      @selected="selectHandler"
    >
      <template slot-scope="{suggestion}">
        <span class="my-suggestion-item">{{ suggestion.item[textField] }}</span>
      </template>
    </vue-autosuggest>
  </b-form-group>
</template>
<script>
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'AutoSuggest',
  components: {
    VueAutosuggest,
  },
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    suggestions: { type: Array, default: () => [] },
    valueReducer: { type: Function, default: option => option.item.id },
    textField: { type: String, default: '' },
    dataProvider: { type: Function, default: () => {} },
    selected: { type: [Number, String], default: '' },
    minSearchChars: { type: Number, default: 3 },
  },
  computed: {
    selectedProxy: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('update:selected', value)
      },
    },
  },
  methods: {
    onInputChange(query) {
      if (query === '' || query === undefined || query.length < this.minSearchChars) {
        return
      }

      this.dataProvider(query)
    },
    selectHandler(selected) {
      this.$emit('update:selected', this.valueReducer(selected))
    },
  },
}
</script>
<style lang="scss">
@import "src/@core/scss/vue/libs/vue-autosuggest.scss";
</style>
